body {
  background-color: #2a5ca6 !important;
}

.main-row {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: space-evenly;
}

.grid-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}
